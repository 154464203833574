.homeContainer {
  margin: 0 auto;
  height: 80vh;
  background-color: #fff;
  color: #fff;
  
  
}


// shows fam prom color behind user's name
.header {
  margin: 0;
  padding: 2%;
  height: 5rem;
  background-color: #472D5B;

  // text-align: center;
}

// little chunk next to user name header
.spacer {
  height: 5rem;
  background-color: #472D5B;
}

.homeContent {
  max-height: 90vh;
  overflow: scroll;
  margin: 0;
  padding: 0;
  background-color: #fff;
 
 
  // overflow-y: hidden;
}

.userSidebar {
  height: 45rem;
  background-color: #fff;

  // border-top: 2px solid white;
  // border-left: 2px solid white;
}

.userInfoContent {
  padding: 2%;
  background-color: #fff;
  
}

.userDashLayout {
  height: 41rem;
  background-color: #472D5B;
 
}

.dashFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 3rem;
  border: 0px solid black;
  background-color: #fff;
  background: #fff;
  
}

.footerItem {
  padding: 0 1rem;
  background-color: #fff;
  
}

.userInfoFooter {
  padding-left: 2%;
  background-color: #fff;
  
}


@media (max-width: 870px) {
  .dashFooter {
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0;
  }
  .footerItem {
    padding: 0;
  
  }
}

@media (max-width: 652px) {
}
