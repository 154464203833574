.tag {
  font-size: 0.9rem;
  width: 5rem;
  text-align: center;
  padding: 2% 0;
}

.docCardContainer {
  display: flex;
  flex-direction: column;
}

.documentCard {
  width: 35rem;
  margin-bottom: 3%;
}

.spinContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
