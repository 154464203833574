.container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.form {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 900px) {
  .container {
    width: 80%;
  }
  .form {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 90%;
  }
  .form {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .container {
    width: 100%;
  }
  .form {
    width: 95%;
  }
}
