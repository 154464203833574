.container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.banner {
  min-height: 9vh;
  background-color: #472d5b;
  color: white;
  padding: 1rem;

  display: flex;
  justify-content: start;
  align-items: center;
}

.form {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formNavigation {
  display: flex;
  justify-content: space-between;
}

.review {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 900px) {
  .banner h1 {
    font-size: 1.3rem;
  }

  .container {
    width: 80%;
  }
  .form {
    width: 75%;
  }
}

.review {
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .container {
    width: 90%;
  }
  .form {
    width: 85%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 100%;
  }
  .form {
    width: 98%;
  }
}
