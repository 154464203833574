.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.contact {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.documents {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 1rem;
  margin-bottom: 5rem;
}

.document {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.checklistsContainer {
  padding: 0.5rem;
  display: flex;
}

.checklist {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.datepicker {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 1280px) {
  .documents {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .contact {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .documents {
    grid-template-columns: 1fr 1fr;
  }

  .contact {
    width: 70%;
  }
}

@media (max-width: 620px) {
  .documents {
    grid-template-columns: 1fr;
  }

  .checklistsContainer {
    flex-flow: column nowrap;
  }

  .contact {
    width: 100%;
  }

  .checklist {
    width: 100%;
    margin-bottom: 1rem;
  }

  .datepicker {
    width: 100%;
    margin-bottom: 1rem;
  }
}
