.dashContainer {
  width: 100%;
  margin: 0 auto;
  border: 2px solid #472d5b;
  border-radius: 10px;
  margin-top: 3%;
}

.headingNav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #472d5b;
}

.dashboard {
  padding: 1%;
  background-color: white;
}

.table {
  border: 5px solid black;
  width: 100%;
}

.cardsContainer {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  padding-left: 2rem;
  justify-content: center;
  border-radius: 1%;
  min-height: 200px;
}

.notifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.icons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  align-items: center;
}

.icon {
  z-index: 2;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px;
}

.title,
.value {
  color: white;
}

.footer {
  background-color: white;
}

@media (max-width: 950px) {
  .cardsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 650px) {
  .headingNav {
    flex-flow: column nowrap;
    text-align: center;
    height: 13vh;
  }
  .cardsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .headingNav {
    height: 18vh;
  }
}
