@import '../global.css';

.container {
  padding: 2%;
}

.icon {
  color: var(--color-primary);
}

.iconDisabled {
  color: rgb(170, 170, 170);
}

.heading {
  margin-bottom: 3%;
}
