.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background-color: #fff;
  color: #472d5b;
}


.header {
  text-align: center;
  padding: 2%;
  width: 100%;
  background-color: #472d5b;
}

.commentsWrapper {
  background-color: rgb(241, 241, 241);
  padding: 2%;
  color: #472d5b;
}

/* .homeContainer {
  border: 3px solid green;
} */
/* Need to move this to a Less file then it should work (at least that's what git hub is telling me) */

/* .homeContainer.ant-layout-sider-trigger {
  position: relative;
} */

@media (max-width: 500px) {
  .info {
    width: 100%;
  }
}
