.progressBar {
  width: 98%;
  margin: 2px auto;
  padding: 2px;
  display: flex;
  border: 3px solid #004477;
  border-radius: 50px;
  color: #8d4982;
  font-size: 1.7rem;
  font-weight: 800;
}
.progressLabel {
  width: 25%;
  text-align: center;
  margin: 0 auto;
}
.progressBarContainer {
  width: 75%;
}
.stepsBar {
  width: 40%;
  background-color: #8d4982;
  border-radius: 0 50px 50px 0;
  color: white;
  text-align: center;
  transition: width 0.5s ease;
}

@media screen and (max-width: 800px) {
  .progressBar {
    font-size: 1rem;
    font-weight: 700;
  }
}
