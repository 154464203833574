@import '~antd/dist/antd.css';

.homeContainer {
  .ant-layout-sider-trigger {
    position: relative;
    background: #472d5b;
  }
}

.userSidebar {
  position: relative;
  background-color: #fff;
}

.ant-menu {
  background: #f2f7f6;
  background-color: #f2f7f6;
  box-shadow: 1px 5px 1px 5px rgba(0, 0, 0, 0.2);
  color-scheme: light;

  width: 100%;
  height: 100%;
  .ant-menu-inline {
    background-color: #fff;
  }
  .ant-menu-item {
    color: #006fba;
    background-color: #fff;
    background: #fff;
    font-size: 14px;
    font-weight: bold;

    border-radius: #472d5b;
    border: #472d5b;
    box-shadow: #472d5b;

    padding-left: #472d5b;
    padding-right: #472d5b;
  }
  .ant-menu-item-selected {
    background-color: #472d5b !important;
    border-radius: 0 20px 20px 0;
    color: white;

    &:hover {
      background-color: #472d5bfa !important;
      color: white;
    }
  }
}

.spacer {
  .ant-layout-sider-trigger {
    background-color: #472d5b;
    background: blanchedalmond;
  }
}

.sidebar-content-container {
  .ant-layout-sider-trigger {
    background-color: #fff;
    background: blanchedalmond;
  }
}

.sideBar {
  .ant-layout-sider-trigger {
    background-color: #fff;
  }
}

.homeContent {
  .ant-layout-sider-trigger {
    background-color: #fff;
  }
}

.uploadDocModal {
  .ant-upload-list-item-name {
    margin-top: 10px;
  }
}
