.nav {
  width: 100%;
  height: 10vh;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.33);
}

img {
  height: 11rem;
  cursor: pointer;
}

.navItems {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 10%;
  cursor: pointer;
  font-size: 1.1rem;
  padding-right: 2rem;
  list-style: none;
}

@media (max-width: 1150px) {
  .navItems {
    width: 15%;
  }
}

@media (max-width: 820px) {
  .navItems {
    width: 20%;
  }
}

@media (max-width: 620px) {
  img {
    height: 9rem;
  }
  .navItems {
    width: 30%;
  }
}

@media (max-width: 520px) {
  img {
    height: 8rem;
  }
  .navItems {
    width: 35%;
  }
}

@media (max-width: 420px) {
  img {
    height: 6rem;
  }
  .navItems {
    width: 45%;
  }
}
