.container {
  width: 30%;
  position: fixed;
  top: 1.5%;
  right: 0.2%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  overflow: visible;
}

.closePanal {
  position: absolute;
  z-index: 11;
  right: 5%;
  top: 3%;
  cursor: pointer;
}

.clearAllNotifications {
  position: absolute;
  z-index: 11;
  right: 0.3%;
  top: -1.5%;
  cursor: pointer;
}

.deleteNotification {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  height: 100vh;
  overflow-y: scroll;
}

@media (max-width: 1400px) {
  .container {
    width: 40%;
  }
}

@media (max-width: 1100px) {
  .container {
    width: 50%;
  }
}
@media (max-width: 1000px) {
  .container {
    width: 55%;
  }
}

@media (max-width: 800px) {
  .container {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .container {
    width: 65%;
  }
}

@media (max-width: 700px) {
  .container {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95%;
  }
}
