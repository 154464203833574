@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital@1&display=swap);
.navbar_nav__1hSvc {
  width: 100%;
  height: 10vh;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.33);
}

img {
  height: 11rem;
  cursor: pointer;
}

.navbar_navItems__2BONd {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 10%;
  cursor: pointer;
  font-size: 1.1rem;
  padding-right: 2rem;
  list-style: none;
}

@media (max-width: 1150px) {
  .navbar_navItems__2BONd {
    width: 15%;
  }
}

@media (max-width: 820px) {
  .navbar_navItems__2BONd {
    width: 20%;
  }
}

@media (max-width: 620px) {
  img {
    height: 9rem;
  }
  .navbar_navItems__2BONd {
    width: 30%;
  }
}

@media (max-width: 520px) {
  img {
    height: 8rem;
  }
  .navbar_navItems__2BONd {
    width: 35%;
  }
}

@media (max-width: 420px) {
  img {
    height: 6rem;
  }
  .navbar_navItems__2BONd {
    width: 45%;
  }
}

.notificationspanal_container__3VLR0 {
  width: 30%;
  position: fixed;
  top: 1.5%;
  right: 0.2%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  overflow: visible;
}

.notificationspanal_closePanal__3jJsR {
  position: absolute;
  z-index: 11;
  right: 5%;
  top: 3%;
  cursor: pointer;
}

.notificationspanal_clearAllNotifications__3JUjr {
  position: absolute;
  z-index: 11;
  right: 0.3%;
  top: -1.5%;
  cursor: pointer;
}

.notificationspanal_deleteNotification__2lSIN {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.notificationspanal_notifications__27g-H {
  display: flex;
  flex-direction: column;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  height: 100vh;
  overflow-y: scroll;
}

@media (max-width: 1400px) {
  .notificationspanal_container__3VLR0 {
    width: 40%;
  }
}

@media (max-width: 1100px) {
  .notificationspanal_container__3VLR0 {
    width: 50%;
  }
}
@media (max-width: 1000px) {
  .notificationspanal_container__3VLR0 {
    width: 55%;
  }
}

@media (max-width: 800px) {
  .notificationspanal_container__3VLR0 {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .notificationspanal_container__3VLR0 {
    width: 65%;
  }
}

@media (max-width: 700px) {
  .notificationspanal_container__3VLR0 {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .notificationspanal_container__3VLR0 {
    width: 95%;
  }
}

.admin_dashContainer__2llsV {
  width: 100%;
  margin: 0 auto;
  border: 2px solid #472d5b;
  border-radius: 10px;
  margin-top: 3%;
}

.admin_headingNav__8A8_H {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #472d5b;
}

.admin_dashboard__3WWDA {
  padding: 1%;
  background-color: white;
}

.admin_table__2OX6d {
  border: 5px solid black;
  width: 100%;
}

.admin_cardsContainer__39oPa {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.admin_card__WE2tT {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  padding-left: 2rem;
  justify-content: center;
  border-radius: 1%;
  min-height: 200px;
}

.admin_notifications__3SoKB {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.admin_icons__1C4bp {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  align-items: center;
}

.admin_icon__27Ka_ {
  z-index: 2;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px;
}

.admin_title__35E8D,
.admin_value__1tdx2 {
  color: white;
}

.admin_footer__3IFT9 {
  background-color: white;
}

@media (max-width: 950px) {
  .admin_cardsContainer__39oPa {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 650px) {
  .admin_headingNav__8A8_H {
    flex-flow: column nowrap;
    text-align: center;
    height: 13vh;
  }
  .admin_cardsContainer__39oPa {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .admin_headingNav__8A8_H {
    height: 18vh;
  }
}

.create_container__2jFkr {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.create_form__EM2tP {
  width: 60%;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

@media (max-width: 900px) {
  .create_container__2jFkr {
    width: 80%;
  }
  .create_form__EM2tP {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .create_container__2jFkr {
    width: 90%;
  }
  .create_form__EM2tP {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .create_container__2jFkr {
    width: 100%;
  }
  .create_form__EM2tP {
    width: 95%;
  }
}

.landlord_tag__sRCrv {
  font-size: 0.9rem;
  width: 5rem;
  text-align: center;
  padding: 2% 0;
}

.landlord_docCardContainer__bcmM2 {
  display: flex;
  flex-direction: column;
}

.landlord_documentCard__a57SH {
  width: 35rem;
  margin-bottom: 3%;
}

.landlord_spinContainer__2TARu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_container__1hqCM {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply_container__3k_lH {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.apply_banner__3A2O1 {
  min-height: 9vh;
  background-color: #472d5b;
  color: white;
  padding: 1rem;

  display: flex;
  justify-content: start;
  align-items: center;
}

.apply_form__5IWII {
  width: 60%;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.apply_formNavigation__oyOfM {
  display: flex;
  justify-content: space-between;
}

.apply_review__2hzuD {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media (max-width: 900px) {
  .apply_banner__3A2O1 h1 {
    font-size: 1.3rem;
  }

  .apply_container__3k_lH {
    width: 80%;
  }
  .apply_form__5IWII {
    width: 75%;
  }
}

.apply_review__2hzuD {
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .apply_container__3k_lH {
    width: 90%;
  }
  .apply_form__5IWII {
    width: 85%;
  }
}

@media (max-width: 600px) {
  .apply_container__3k_lH {
    width: 100%;
  }
  .apply_form__5IWII {
    width: 98%;
  }
}

.container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.form {
  width: 60%;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.formNavigation {
  display: flex;
  justify-content: space-between;
}
.review {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.fpCardTitle {
  display: flex;
  align-items: center;
}
@media (max-width: 900px) {
  .container {
    width: 80%;
  }
  .form {
    width: 75%;
  }
}
.review {
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .container {
    width: 90%;
  }
  .form {
    width: 85%;
  }
}
@media (max-width: 600px) {
  .container {
    width: 100%;
  }
  .form {
    width: 98%;
  }
}
@media (max-width: 475px) {
  .container {
    padding: 0;
  }
  .form {
    width: 100%;
  }
  .formNavigation {
    padding: 0 1rem 1rem;
  }
}

.homeContainer .ant-layout-sider-trigger {
  position: relative;
  background: #472d5b;
}
.userSidebar {
  position: relative;
  background-color: #fff;
}
.ant-menu {
  background: #f2f7f6;
  background-color: #f2f7f6;
  box-shadow: 1px 5px 1px 5px rgba(0, 0, 0, 0.2);
  color-scheme: light;
  width: 100%;
  height: 100%;
}
.ant-menu .ant-menu-inline {
  background-color: #fff;
}
.ant-menu .ant-menu-item {
  color: #006fba;
  background-color: #fff;
  background: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: #472d5b;
  border: #472d5b;
  box-shadow: #472d5b;
  padding-left: #472d5b;
  padding-right: #472d5b;
}
.ant-menu .ant-menu-item-selected {
  background-color: #472d5b !important;
  border-radius: 0 20px 20px 0;
  color: white;
}
.ant-menu .ant-menu-item-selected:hover {
  background-color: #472d5bfa !important;
  color: white;
}
.spacer .ant-layout-sider-trigger {
  background-color: #472d5b;
  background: blanchedalmond;
}
.sidebar-content-container .ant-layout-sider-trigger {
  background-color: #fff;
  background: blanchedalmond;
}
.sideBar .ant-layout-sider-trigger {
  background-color: #fff;
}
.homeContent .ant-layout-sider-trigger {
  background-color: #fff;
}
.uploadDocModal .ant-upload-list-item-name {
  margin-top: 10px;
}

.homeContainer {
  margin: 0 auto;
  height: 80vh;
  background-color: #fff;
  color: #fff;
}
.header {
  margin: 0;
  padding: 2%;
  height: 5rem;
  background-color: #472D5B;
}
.spacer {
  height: 5rem;
  background-color: #472D5B;
}
.homeContent {
  max-height: 90vh;
  overflow: scroll;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.userSidebar {
  height: 45rem;
  background-color: #fff;
}
.userInfoContent {
  padding: 2%;
  background-color: #fff;
}
.userDashLayout {
  height: 41rem;
  background-color: #472D5B;
}
.dashFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 3rem;
  border: 0px solid black;
  background-color: #fff;
  background: #fff;
}
.footerItem {
  padding: 0 1rem;
  background-color: #fff;
}
.userInfoFooter {
  padding-left: 2%;
  background-color: #fff;
}
@media (max-width: 870px) {
  .dashFooter {
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0;
  }
  .footerItem {
    padding: 0;
  }
}

:root {
  --color-primary: #8d4881;
  --color-secondary-blue: #006ab3;
  --color-secondary-dark-blue: #004477;
  --color-secondary-yellow: #fdbe57;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
}

h1 {
  font-weight: 700;
}

h2,
h3 {
  font-weight: 400;
}

p {
  font-weight: 300;
  margin: 0;
  padding: 0;
}

hr {
  color: #8c8c8c57;
  margin-bottom: 1rem;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
}

.statusBar_container__2lrrM {
  padding: 2%;
}

.statusBar_icon__Pw4qR {
  color: var(--color-primary);
}

.statusBar_iconDisabled__LFczs {
  color: rgb(170, 170, 170);
}

.statusBar_heading__3UJZl {
  margin-bottom: 3%;
}

.home_container__10kUS {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  background-color: #fff;
  color: #472d5b;
}


.home_header__3OpYQ {
  text-align: center;
  padding: 2%;
  width: 100%;
  background-color: #472d5b;
}

.home_commentsWrapper__2eDNm {
  background-color: rgb(241, 241, 241);
  padding: 2%;
  color: #472d5b;
}

/* .homeContainer {
  border: 3px solid green;
} */
/* Need to move this to a Less file then it should work (at least that's what git hub is telling me) */

/* .homeContainer.ant-layout-sider-trigger {
  position: relative;
} */

@media (max-width: 500px) {
  .home_info__TnHcW {
    width: 100%;
  }
}

.landing_header__25sLt {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to right bottom, #fffafa, #fffafaaa),
    url(/static/media/rentdue.f8271b8d.png);
  background-size: cover;
  background-position: center;
  color: #f5f5dc;
}

.landing_title__hVGJv {
  text-align: center;
}

.landing_contentContainer__1KIjW {
  margin-top: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.landing_header__25sLt * {
  color: #004477;
}

.landing_warning__1yZIq {
  color: #c23d09;
}

.landing_header__25sLt p {
  width: 60%;
}

.landing_link__3VFs_ {
  color: var(--color-secondary-blue);
}

@media (max-width: 1200px) {
  .landing_header__25sLt p {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .landing_header__25sLt {
    font-size: 1.3rem;
  }

  .landing_header__25sLt p {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .landing_header__25sLt {
    font-size: 1.1rem;
  }
}

@media (max-width: 738px) {
  h1 {
    font-size: 2rem;
  }
}

@media (max-width: 570px) {
  .landing_contentContainer__1KIjW h1 {
    text-align: center;
  }
}
.login_container__2hSVO {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_fields__1mr_C {
  display: grid;
  grid-gap: 2rem;
  width: 40%;
}

.login_loginButton__2KoxN {
  width: 100%;
}

@media (max-width: 930px) {
  .login_fields__1mr_C {
    width: 50%;
  }
}

@media (max-width: 630px) {
  .login_fields__1mr_C {
    width: 90%;
    grid-gap: 1.5rem;
  }
}

.password_container__3anwU {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    justify-content: center;
    align-items: center;
}
.progressBar {
  width: 98%;
  margin: 2px auto;
  padding: 2px;
  display: flex;
  border: 3px solid #004477;
  border-radius: 50px;
  color: #8d4982;
  font-size: 1.7rem;
  font-weight: 800;
}
.progressLabel {
  width: 25%;
  text-align: center;
  margin: 0 auto;
}
.progressBarContainer {
  width: 75%;
}
.stepsBar {
  width: 40%;
  background-color: #8d4982;
  border-radius: 0 50px 50px 0;
  color: white;
  text-align: center;
  transition: width 0.5s ease;
}

@media screen and (max-width: 800px) {
  .progressBar {
    font-size: 1rem;
    font-weight: 700;
  }
}

.programs_container__AqCLU {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
}

.programs_programs__C_vvS {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

.programs_actions__13BHY {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

@media (max-width: 1050px) {
  .programs_programs__C_vvS {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 750px) {
  .programs_programs__C_vvS {
    grid-template-columns: 1fr;
  }
}

.request_container__GM_qq {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.request_contact__1lMt0 {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.request_documents__1Z5Yl {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 5rem;
}

.request_document__1txW0 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.request_checklistsContainer__1r6Ma {
  padding: 0.5rem;
  display: flex;
}

.request_checklist__3_EWc {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.request_datepicker__3dKAd {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 1280px) {
  .request_documents__1Z5Yl {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .request_contact__1lMt0 {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .request_documents__1Z5Yl {
    grid-template-columns: 1fr 1fr;
  }

  .request_contact__1lMt0 {
    width: 70%;
  }
}

@media (max-width: 620px) {
  .request_documents__1Z5Yl {
    grid-template-columns: 1fr;
  }

  .request_checklistsContainer__1r6Ma {
    flex-flow: column nowrap;
  }

  .request_contact__1lMt0 {
    width: 100%;
  }

  .request_checklist__3_EWc {
    width: 100%;
    margin-bottom: 1rem;
  }

  .request_datepicker__3dKAd {
    width: 100%;
    margin-bottom: 1rem;
  }
}

:root {
  --color-primary: #8d4881;
  --color-secondary-blue: #006ab3;
  --color-secondary-dark-blue: #004477;
  --color-secondary-yellow: #fdbe57;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
}

h1 {
  font-weight: 700;
}

h2,
h3 {
  font-weight: 400;
}

p {
  font-weight: 300;
  margin: 0;
  padding: 0;
}

hr {
  color: #8c8c8c57;
  margin-bottom: 1rem;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
}

.profile_container__GP-JK {
  padding: 1rem;
}

.profile_documents__1erm4 {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
}

