.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.programs {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  align-items: center;
}

@media (max-width: 1050px) {
  .programs {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 750px) {
  .programs {
    grid-template-columns: 1fr;
  }
}
